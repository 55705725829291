import css from "styled-jsx/css";
import colors from "/styles/colors";
import { MODAL_BACKGROUND, HIDDEN, ZERO } from "/constants/z-index";
import { MOBILE_QUERY_MAX_WIDTH_M } from "/constants/vp-sizes";

export const componentStyles = css`
  .side-menu-mobile {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 65px;
    left: 0;
    display: flex;
    justify-content: center;
    transition: top 0.1s ease-in-out, opacity 0.1s ease-in-out;
    opacity: 0;
    z-index: ${HIDDEN};
    transform: translate(-100%);

    &.open {
      transform: translate(0);
      opacity: 1;
      z-index: ${ZERO};

      .side-menu-mobile-dialog {
        opacity: 1;
      }
    }

    &.scroll-mode {
      top: 58px;
    }

    .side-menu-mobile-dialog {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: ${colors["brand-pink"]};
      z-index: ${MODAL_BACKGROUND + 1};
      align-self: center;

      section.links-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 24px;

        :global(a) {
          margin: 16px 0;
          display: block;
          color: ${colors["white"]};
          font-weight: 300;
          text-decoration: none;
          font-size: 2.25rem;
        }
      }

      section.buttom-bottons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;

        .download-app-wrapper {
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .side-menu-mobile {
      .side-menu-mobile-dialog {
        section.links-section {
          align-items: center;

          :global(a) {
            font-size: 1.375rem;
            margin: 8px 0;
          }
        }
      }
    }
  }
`;

export const decorationsStyles = css.global`
  .side-menu-mobile-dialog {
    section.buttom-bottons {
      .download-app-wrapper {
        margin: 0 8px;
        img {
          width: 45vw;
          max-width: 200px;
        }
      }
      .decoration svg {
        width: 40px;
        rect {
          fill: ${colors["white"]};
        }
      }
    }
  }
`;
