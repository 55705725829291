import css from "styled-jsx/css";

export default css`
  .__svg-spinner_circle {
    transition-property: transform;
    animation-name: __svg-spinner_infinite-spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: center;
  }
  @keyframes __svg-spinner_infinite-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
