import { PureComponent } from "react";
import classnames from "classnames";
import { componentStyles, decorationsStyles } from "./side-menu-mobile-component.scss";
import { SideMenuMobile } from "/constants/links";
import OutlineButtonComponent from "../../../outline-button/outline-button-component";
import { isAndroid } from "/utils/mobile";
import { IOS_APP_DOWNLOAD_LINK, ANDROID_APP_DOWNLOAD_LINK, LOGIN_PAGE_LINK } from "/constants/links";

import { normalizeUrl } from "/utils/url";
import { trackEvent } from "/services/bigbrain-service";
import { SIDE_MENU_LINK_CLICK } from "/constants/bigbrain-event-types";

import SignupLeftDecoration from "/static/svg/decorators/signup/signup-decoration-left.svg";
import SignupRightDecoration from "/static/svg/decorators/signup/signup-decoration-right.svg";
import PictureComponent from "components/core/picture/picture-component";
import { withGenericConfig } from "/contexts/generic-config-context";

class SideMenuMobileComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAndroidPhone: false
    };

    this.downloadMobileApp = this.downloadMobileApp.bind(this);
  }

  downloadMobileApp() {
    const { isAndroidPhone } = this.state;
    BigBrain("track", "hp_store_clicked", { kind: isAndroidPhone ? "andriod" : "ios" });
    window.location.href = isAndroidPhone ? ANDROID_APP_DOWNLOAD_LINK : IOS_APP_DOWNLOAD_LINK;
  }

  trackLinkClick(event) {
    const { target } = event;
    const href = normalizeUrl(target.href);
    trackEvent(SIDE_MENU_LINK_CLICK, { kind: href, placement: "mobile" });
  }

  componentDidMount() {
    this.setState({ isAndroidPhone: isAndroid() });
  }

  renderAppButton() {
    const { isAndroidPhone } = this.state;
    const imgSrc = isAndroidPhone ? "/static/img/apps/google_play.png" : "/static/img/apps/app_store.png";
    return <PictureComponent src={imgSrc} />;
  }

  render() {
    const { open, scrollMode, translate, mobileHeaderLinks } = this.props;
    const header = mobileHeaderLinks;

    return (
      <div className={classnames(`side-menu-mobile`, { open, "scroll-mode": scrollMode })}>
        <div className="side-menu-mobile-dialog">
          <section className="links-section">
            {Object.keys(header.links).map((title) => (
              <div className="side-nav-link-wrapper" key={title}>
                <OutlineButtonComponent
                  url={header.links[title]}
                  text={translate(title)}
                  buttonStyle="textual"
                  linkClassName={"side-nav-link"}
                />
              </div>
            ))}
          </section>
          <section className="buttom-bottons">
            <div className="left-decoration decoration">
              <SignupLeftDecoration key="left-decoration decoration" />
            </div>

            <div className="download-app-wrapper" onClick={this.downloadMobileApp}>
              {this.renderAppButton()}
            </div>

            <div className="right-decoration decoration">
              <SignupRightDecoration key="right-decoration" />
            </div>
          </section>
        </div>
        <style jsx>{componentStyles}</style>
        <style jsx>{decorationsStyles}</style>
      </div>
    );
  }
}

SideMenuMobileComponent.defaultProps = {
  open: false,
  mobileHeaderLinks: SideMenuMobile
};

export default withGenericConfig(SideMenuMobileComponent);
