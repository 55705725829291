import { PureComponent } from "react";

import SignupFormComponent from "/components/core/signups/base-signup-form/signup-form-component";

import { LOGIN_PAGE_LINK } from "constants/links";
import { TOPBAR_LOGIN_BUTTON_CLASS } from "constants/topbar-selectors";

import OutlineButtonComponent from "../../outline-button/outline-button-component";
import { withGenericConfig } from "contexts/generic-config-context";

import { componentStyles } from "./topbar-login-signup-component.scss";

class TopbarLoginSignupComponent extends PureComponent {
  render() {
    const { loginUrl, theme, isInScrollMode, signupTrackingPlacement, translate, buttonText, inputPlaceholder } =
      this.props;

    const buttonTextToUse = buttonText || translate("signup.signup");
    const inputPlaceholderToUse = inputPlaceholder || translate("signup.emailInputShortPlaceholder");

    return (
      <div className="topbar-login-signup-component">
        <div className="topbar-login-signup-seperator" />
        <div className="topbar-login-signup-login-button-wrapper">
          <OutlineButtonComponent
            url={loginUrl || LOGIN_PAGE_LINK}
            text={translate("signup.logIn")}
            theme={theme}
            isInScrollMode={isInScrollMode}
            buttonStyle="textual"
            linkClassName={`${TOPBAR_LOGIN_BUTTON_CLASS} login-top-bar`}
          />
        </div>

        {isInScrollMode && (
          <div className="topbar-login-signup-base-signup-wrapper">
            <SignupFormComponent
              {...this.props}
              ctaText={buttonTextToUse}
              useCtaText={!!buttonTextToUse}
              inputPlaceholder={inputPlaceholderToUse}
              preventErrorMessage={true}
              placement={signupTrackingPlacement}
            />
          </div>
        )}

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

TopbarLoginSignupComponent.defaultProps = {
  signupTrackingPlacement: "topbar"
};

export default withGenericConfig(TopbarLoginSignupComponent);
