import { PureComponent } from "react";
import SideMenuMobileComponent from "./side-menu-mobile/side-menu-mobile-component";

export default class SideMenuComponent extends PureComponent {
  render() {
    const { isOpen, scrollMode, mobileHeaderLinks } = this.props;

    return <SideMenuMobileComponent open={isOpen} scrollMode={scrollMode} mobileHeaderLinks={mobileHeaderLinks} />;
  }
}

SideMenuComponent.defaultProps = {
  isOpen: false
};
