import css from "styled-jsx/css";
import colors from "/styles/colors";
import { MODAL_BACKGROUND } from "/constants/z-index";

export const modalSignup = css`
  .modal-signup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-weight: 300;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    padding: 0 16px;
    
    background-color: rgba(40, 40, 40, 0.94);
    z-index: ${MODAL_BACKGROUND};
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: ${colors["modal-signup-close-grey"]};
    fill: currentColor;

    :global(svg) {
      width: 35px;
      height: 35px;
    }
  }

  .main-title {
    font-size: 2.7rem;
    color: ${colors["modal-signup-title-yellow"]};
    text-align: center;
  }

  .secondary-title {
    font-size: 1.575rem;
    color: white;
    text-align: center;
    margin-top:8px;
    margin-bottom: 8px;
  }

  .signup-wrapper{
    display: flex;
    justify-content: center;
  }

  .bottom-part {
    display: flex;
    margin-top: 8px;
  }

  .line {
    background-clip: content-box;
    width: 60px;
    padding: 14px 0;
    height: 30px;
    background-color: white;
  }

  .bottom-text {
    font-size: 1rem;
    color: white;
    margin: 0 10px;
    vertical-align: top;
    line-height: 30px;
  }

`;
