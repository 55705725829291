import { PureComponent } from "react";
import TopBarDesktopComponent from "./desktop/top-bar-desktop-component";
import TopBarMobileComponent from "./mobile/top-bar-mobile-component";
import componentStyles from "./top-bar-component.scss";

export default class TopBarNewComponent extends PureComponent {
  render() {
    return (
      <div className="top-bar-component">
        <div className="top-bar-desktop-wrapper">
          <TopBarDesktopComponent {...this.props} />
        </div>
        <div className="top-bar-mobile-wrapper">
          <TopBarMobileComponent {...this.props} />
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
