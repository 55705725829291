import { PureComponent } from "react";
import classnames from "classnames";
import { withRouter } from "next/router";

import { sameUrls } from "/utils/url";

import colors from "styles/colors";
import { LOGIN_PAGE_LINK } from "constants/links";
import { TOPBAR_LOGIN_BUTTON_CLASS } from "/constants/topbar-selectors";
import TopBarBaseComponent from "../top-bar-base/top-bar-base-component";
import OutlineButtonComponent from "../outline-button/outline-button-component";
import TopBarDesktopLinkWithMenuComponent from "./top-bar-desktop-link-with-menu/top-bar-desktop-link-with-menu-component";

import TopbarLoginSignupComponent from "./topbar-login-signup/topbar-login-signup-component";

import { headerLinksStyles, menuStyles } from "./top-bar-desktop-component.scss";

import { getHeaderComponents } from "services/localization/localization-service";
import { withAbTests } from "/contexts/ab-tests-context";
import { withGenericConfig } from "contexts/generic-config-context";

const POSSIBLE_HOVER_COLORS = ["brand-purple", "brand-green", "brand-pink", "brand-blue"];

class TopbarDesktopComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isInScrollMode: false
    };

    this.onScrollModeChange = this.onScrollModeChange.bind(this);
    this.renderHeaderLink = this.renderHeaderLink.bind(this);
  }

  onScrollModeChange(isInScrollMode) {
    this.setState({ isInScrollMode });
  }

  getLinkClassName(headerLink) {
    const { router = {} } = this.props;
    const selected = headerLink.menuLinks
      ? !!this.getMenuLinkActiveLinkText(headerLink)
      : sameUrls(headerLink, router.asPath);
    return classnames("top-bar-link", { selected });
  }

  getMenuLinkActiveLinkText(headerLinkMenu) {
    const { router = {} } = this.props;
    const { links, bottomLinks } = headerLinkMenu.menuLinks;

    const allLinks = { ...links, ...bottomLinks };

    let activeLinkText = null;
    Object.keys(allLinks).forEach((linkText) => {
      if (sameUrls(allLinks[linkText], router.asPath)) activeLinkText = linkText;
    });

    return activeLinkText;
  }

  renderLinkWithMenu(headerLinkText, headerLinkMenu, hoverColor) {
    const { localeId } = this.props;
    const className = headerLinkMenu.className;

    return (
      <div className={`${className} topbar-menu-wrapper`}>
        <TopBarDesktopLinkWithMenuComponent
          menuItems={headerLinkMenu.menuLinks}
          hoverColor={hoverColor}
          localeId={localeId}
        >
          {this.renderLink(headerLinkText, headerLinkMenu, hoverColor)}
        </TopBarDesktopLinkWithMenuComponent>
        <style jsx>{menuStyles}</style>
      </div>
    );
  }

  getLinkUrl(headerLink) {
    return headerLink.menuLinks ? undefined : headerLink;
  }

  renderLink(headerLinkText, headerLink, hoverColor) {
    const { theme, isInScrollMode, translate } = this.props;
    const linkUrl = this.getLinkUrl(headerLink);

    return (
      <span className="outline-button-header-link-wrapper">
        <OutlineButtonComponent
          url={linkUrl}
          text={translate(headerLinkText)}
          theme={theme}
          isInScrollMode={isInScrollMode}
          buttonStyle="textual"
          linkClassName={this.getLinkClassName(headerLink)}
        />
        <style jsx>{`
          .outline-button-header-link-wrapper {
            :global(.outline-button.textual a.selected),
            :global(.outline-button.textual a:hover) {
              color: ${colors["dark-indigo"]};
            }
          }
        `}</style>
      </span>
    );
  }

  getHeaderLinks() {
    const { desktopHeaderLinks, shortHeader, localeId } = this.props;

    return desktopHeaderLinks ? desktopHeaderLinks : getHeaderComponents(localeId, shortHeader);
  }

  renderHeaderLink(headerLinkText, index) {
    const header = this.getHeaderLinks();

    const headerLink = header.links[headerLinkText];
    if (!headerLink) return null;

    const color = POSSIBLE_HOVER_COLORS[index % POSSIBLE_HOVER_COLORS.length];

    return (
      <div className="top-header-link-wrapper" key={headerLinkText}>
        {headerLink.menuLinks
          ? this.renderLinkWithMenu(headerLinkText, headerLink, color)
          : this.renderLink(headerLinkText, headerLink, color)}
      </div>
    );
  }

  renderLoginLink() {
    const { theme, isInScrollMode, loginUrl, translate } = this.props;

    return (
      <div className="top-header-login-link-wrapper">
        <OutlineButtonComponent
          url={loginUrl || LOGIN_PAGE_LINK}
          text={translate("signup.logIn")}
          theme={theme}
          isInScrollMode={isInScrollMode}
          buttonStyle="circled"
          linkClassName={`${TOPBAR_LOGIN_BUTTON_CLASS} login-top-bar`}
        />
      </div>
    );
  }

  renderLoginSignupComponentWithSeperator() {
    const { isInScrollMode } = this.state;

    return (
      <div className="topbar-login-signup-wrapper">
        <TopbarLoginSignupComponent isInScrollMode={isInScrollMode} />
      </div>
    );
  }

  render() {
    const header = this.getHeaderLinks();
    const headerLinksText = Object.keys(header.links);

    return (
      <TopBarBaseComponent {...this.props} onScrollModeChange={this.onScrollModeChange}>
        <div className="navbar-menu">
          {headerLinksText.map(this.renderHeaderLink)}

          {this.renderLoginLink()}

          <style jsx>{headerLinksStyles}</style>
        </div>
      </TopBarBaseComponent>
    );
  }
}

TopbarDesktopComponent.defaultProps = {
  stickyMode: false,
  allowSocialButtons: false,
  socialButtonsColor: "black",
  socialButtonBlogStyle: false,
  translate: (key) => key
};

export default withAbTests(withGenericConfig(withRouter(TopbarDesktopComponent)));
