import css from "styled-jsx/css";
import colors from "styles/colors";

export const menuItemStyles = function(hoverColor = "brand-purple") {
  return css`
    .menu-links-wrapper {
      .menu-links-items {
        display: flex;
      }
      .menu-links {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 240px;

        .menu-link-item {
          flex: 1 1 auto;

          a {
            font-size: 0.875rem;
            padding: 12px 16px;

            color: ${colors["black"]};
            white-space: nowrap;
            text-decoration: none;
            display: block;
          }

          &.selected a {
            font-weight: 500;
          }

          &:hover a {
            color: ${colors[hoverColor]};
          }
        }
      }

      .bottom-link {
        margin: 16px;
        a {
          color: ${colors["black"]};

          &.selected {
            font-weight: 500;
          }

          &:hover {
            color: ${colors[hoverColor]};
          }
        }
      }
    }
  `;
};
