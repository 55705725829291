import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
import colors from "styles/colors";

export const mobileComponentStyles = css.global`
  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    nav.navbar,
    nav.navbar.scroll-mode {
      padding: 12px 8px;

      .logo-wrapper {
        .homepage-logo {
          padding: 0 8px;

          :global(svg) {
            width: 167px;
          }
        }
      }
    }
    .navbar-padding {
      height: 54px;
    }

    .social-share-buttons-wrapper {
      display: none;
    }

    nav.navbar:not(.scroll-mode) {
      .homepage-logo.mobile-light-theme {
        svg {
          ellipse,
          path {
            fill: ${colors["white"]};
          }
        }
      }

      .homepage-logo.mobile-black-theme {
        svg {
          ellipse,
          path {
            fill: ${colors["black"]};
          }
        }
      }
    }
  }
`;
