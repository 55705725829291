import { PureComponent } from "react";
import classnames from "classnames";

import componentStyles from "./social-share-buttons-component.scss";

import FacebookShareLogo from "static/svg/social-share/share-facebook.svg";
import TwitterShareLogo from "static/svg/social-share/share-twitter.svg";
import LinkedinShareLogo from "static/svg/social-share/share-linkedin.svg";
import StoriesFacebookShareLogo from "static/svg/social-share/stories/stories-share-facebook.svg";
import StoriesTwitterShareLogo from "static/svg/social-share/stories/stories-share-twitter.svg";
import StoriesLinkedinShareLogo from "static/svg/social-share/stories/stories-share-linkedin.svg";

import { trackEvent } from "/services/bigbrain-service";
import { SOCIAL_SHARE } from "/constants/bigbrain-event-types";

import {
  FACEBOOK_SHARE_URL_PREFIX,
  TWITTER_SHARE_URL_PREFIX,
  LINKEDIN_SHARE_URL_PREFIX
} from "/constants/social-share-urls";

export default class SocialShareButtonsComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.shareFacebook = this.shareFacebook.bind(this);
    this.shareTwitter = this.shareTwitter.bind(this);
    this.shareLinkedin = this.shareLinkedin.bind(this);
  }

  sharePage(urlPrefix, kind) {
    trackEvent(SOCIAL_SHARE, { kind });
    window.open(urlPrefix + document.URL);
  }

  shareFacebook() {
    this.sharePage(FACEBOOK_SHARE_URL_PREFIX, "facebook");
  }

  shareTwitter() {
    this.sharePage(TWITTER_SHARE_URL_PREFIX, "twitter");
  }

  shareLinkedin() {
    this.sharePage(LINKEDIN_SHARE_URL_PREFIX, "linkedin");
  }

  render() {
    const { direction, color, scrollMode, blogStyle, storiesLogos, className, style } = this.props;
    const directionValue = blogStyle ? "vertical" : direction;
    const colorValue = blogStyle ? "white" : color;

    const FacebookLogo = storiesLogos ? StoriesFacebookShareLogo : FacebookShareLogo;
    const TwitterLogo = storiesLogos ? StoriesTwitterShareLogo : TwitterShareLogo;
    const LinkedinLogo = storiesLogos ? StoriesLinkedinShareLogo : LinkedinShareLogo;
    return (
      <div
        className={classnames("social-share-buttons-component", directionValue, colorValue, className, {
          "scroll-mode": scrollMode,
          "blog-style": blogStyle
        })}
        style={style}
      >
        <div className="share-button-container facebook-share" onClick={this.shareFacebook}>
          <FacebookLogo />
        </div>
        <div className="share-button-container twitter-share" onClick={this.shareTwitter}>
          <TwitterLogo />
        </div>
        <div className="share-button-container linkedin-share" onClick={this.shareLinkedin}>
          <LinkedinLogo />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

SocialShareButtonsComponent.defaultProps = {
  direction: "horizontal",
  color: "black",
  stickyMode: false,
  blogStyle: false
};
