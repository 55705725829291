import { PureComponent } from "react";
import MenuContentComponent from "./menu-content/menu-content-component";
import { componentStyles } from "./menu-component.scss";

export default class OutlineButtonWithMenuComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false
    };

    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
  }

  showMenu() {
    this.setState({ showMenu: true });
  }

  hideMenu() {
    this.setState({ showMenu: false });
  }

  render() {
    const { buttonLink, menu, className, buttonRenderer } = this.props;

    return (
      <div className={`menu-component ${className}`} onMouseEnter={this.showMenu} onMouseLeave={this.hideMenu}>
        <div className="button-wrapper">{buttonRenderer && buttonRenderer()}</div>
        {this.state.showMenu && (
          <div className="menu-content-wrapper" style={menu.style}>
            <MenuContentComponent {...menu} />
          </div>
        )}
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

OutlineButtonWithMenuComponent.defaultProps = {
  menu: {},
  buttonLink: {}
};
