import css from "styled-jsx/css";
import colors from "/styles/colors";
import { TOP_BAR } from "/constants/z-index";
import { TABLET_QUERY_MIN_WIDTH } from "constants/vp-sizes";

export default css.global`
  .social-share-buttons-component {
    display: flex;
    align-items: flex-end;

    &.horizontal {
      height: 40px;
      padding: 0 24px;
    }

    &.vertical {
      flex-direction: column;
      align-items: flex-start;
    }

    &.blog-style {
      z-index: ${TOP_BAR};
      position: fixed;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      .share-button-container {
        padding: 0;
        margin-bottom: 1px;
        min-width: 52px;
        min-height: 55px;
        position: relative;

        @media (max-width: ${TABLET_QUERY_MIN_WIDTH}) {
          min-width: 22px;
          min-height: 25px;
        }

        // Keep icons white on hover
        svg {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          path {
            &:first-child {
              fill: ${colors["white"]};
            }
          }
        }

        transition: margin 0.2s;
        &:hover {
          margin-left: 3px;

          &.facebook-share {
            background-color: #008fcc;
          }
        }

        &.twitter-share {
          background-color: #61caf7;
        }

        &.facebook-share {
          background-color: #00a1e5;
        }

        &.linkedin-share {
          background-color: #0086c3;
        }
      }
    }

    .share-button-container {
      padding: 8px;
      cursor: pointer;
    }

    &.black {
      .share-button-container:not(:hover) {
        path {
          &:first-child {
            fill: ${colors["black"]};
          }
        }
      }
    }

    &.white {
      .share-button-container:not(:hover) {
        path {
          &:first-child {
            fill: ${colors["white"]};
          }
        }
      }
    }

    &.scroll-mode:not(.blog-style) {
      .share-button-container:not(:hover) {
        path {
          &:first-child {
            fill: ${colors["black"]};
          }
        }
      }
    }
  }
`;
