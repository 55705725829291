import { PureComponent } from "react";
import classnames from "classnames";

import OutlineButtonComponent from "../../outline-button/outline-button-component";
import MenuComponent from "/components/core/menu/menu-component";
import { withGenericConfig } from "contexts/generic-config-context";

import { menuItemStyles } from "./top-bar-desktop-link-with-menu-component.scss";

class TopBarDesktopLinkWithMenuComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.outlineButtonRenderer = this.outlineButtonRenderer.bind(this);
    this.contentRenderer = this.contentRenderer.bind(this);
  }

  isLinkItemSelected(linkItemText) {
    const { activeLinkText } = this.props;
    return linkItemText === activeLinkText;
  }

  contentRenderer() {
    const { menuItems, hoverColor, translate } = this.props;
    const menuItemsText = Object.keys(menuItems.links);
    const bottomLinksText = menuItems.bottomLinks ? Object.keys(menuItems.bottomLinks) : [];

    const stylesCss = menuItemStyles(hoverColor);
    return (
      <div className="menu-links-wrapper">
        <div className="menu-links-items">
          <div className="menu-links">
            {menuItemsText.map(menuItemText => {
              const menuItemUrl = menuItems.links[menuItemText];
              return (
                <div
                  className={classnames("menu-link-item", {
                    selected: this.isLinkItemSelected(menuItemText)
                  })}
                  key={menuItemText}
                >
                  <a href={menuItemUrl}>{translate(menuItemText)}</a>
                </div>
              );
            })}
          </div>
        </div>
        {bottomLinksText.length > 0 && (
          <div className="bottom-link">
            {bottomLinksText.map(buttomLinkText => (
              <a
                key={buttomLinkText}
                className={classnames("button-link-item", {
                  selected: this.isLinkItemSelected(buttomLinkText)
                })}
                href={menuItems.bottomLinks[buttomLinkText]}
              >
                {translate(buttomLinkText)}
              </a>
            ))}
          </div>
        )}
        <style jsx>{stylesCss}</style>
      </div>
    );
  }

  outlineButtonRenderer() {
    const { button, children } = this.props;
    return children ? children : <OutlineButtonComponent {...button} />;
  }

  render() {
    const menuProps = { contentRenderer: this.contentRenderer };
    return (
      <div className="top-bar-desktop-menu-component">
        <MenuComponent buttonRenderer={this.outlineButtonRenderer} menu={menuProps} />
      </div>
    );
  }
}

export default withGenericConfig(TopBarDesktopLinkWithMenuComponent);
