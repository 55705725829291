import css from "styled-jsx/css";
import colors from "styles/colors";
import { TOP_BAR } from "/constants/z-index";

export const topBarBannerComponentStyles = css`
  .top-bar-banner-component {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    font-weight: 300;
    font-size: 0.875rem;
    height: 32px;
    width: 100%;
    background-color: ${colors["black"]};
    color: ${colors["white"]};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${TOP_BAR + 1};

    .banner-text {
      margin-right: 8px;
    }

    :global(.banner-signup-button) {
      padding: 4px 18px;
      font-size: 0.731rem;
    }
  }
`;
