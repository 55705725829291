import css from "styled-jsx/css";
import colors from "styles/colors";
import { TOP_BAR } from "/constants/z-index";
import { MOBILE_QUERY_MAX_WIDTH, MAX_DESKTOP_CONTENT_WIDTH } from "/constants/vp-sizes";

export const componentStyles = css`
  nav.navbar {
    position: fixed;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
    padding: 12px;
    z-index: ${TOP_BAR};
    transition: padding 0.2s ease-in-out;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;

    .top-bar-content {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      display: flex;
      margin: auto;
    }

    &.with-top-bar-banner {
      top: 32px;
    }

    &.sticky-mode {
      position: static;
    }

    .logo-wrapper {
      display: flex;
      flex: 1;

      .logo-link {
        display: flex;
      }
    }

    .social-share-buttons-wrapper {
      align-self: center;
    }

    &.scroll-mode:not(.sticky-mode) {
      background-color: ${colors["white"]} !important;
      border-bottom-color: ${colors["grey"]};
      border-bottom-style: solid;
      border-bottom-width: 1px;
      padding: 8px;

      .logo-wrapper {
        .homepage-logo {
          :global(.monday-logo-image) {
            width: 167px;
          }
        }
      }
    }
  }

  .navbar-padding {
    height: 65px;

    &.sticky-mode {
      display: none;
    }

    &.top-banner {
      height: 85px;
    }
  }
`;

export const homepageLogoStyles = css.global`
  nav.navbar {
    .homepage-logo {
      display: inline-flex;
      align-items: center;
      padding: 0 16px;

      .monday-logo-image {
        transition: width 0.2s ease-in-out;
        width: 167px;
      }
    }

    &:not(.scroll-mode) {
      .homepage-logo.light-theme {
        svg {
          .text-shapes,
          .m-shapes {
            fill: ${colors["white"]};
          }
        }
      }

      .homepage-logo.black-theme {
        svg {
          .text-shapes,
          .m-shapes {
            fill: ${colors["black"]};
          }
        }
      }

      .homepage-logo.light-color-theme {
        svg {
          .text-shapes {
            fill: ${colors["white"]};
          }
        }
      }
    }
  }
`;
