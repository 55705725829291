import { PureComponent } from "react";
import classnames from "classnames";
import { menuStyles } from "./menu-content-component.scss";
import onClickOutside from "react-onclickoutside";

class MenuContentComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    const { closeMenu } = this.props;
    if (closeMenu) closeMenu();
  }

  render() {
    const { style } = this.props;
    return (
      <div className="menu-content-component" style={style}>
        {this.props.contentRenderer()}
        <style jsx> {menuStyles}</style>
      </div>
    );
  }
}

export default onClickOutside(MenuContentComponent);
