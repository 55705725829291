import css from "styled-jsx/css";
import colors from "styles/colors";

export const headerLinksStyles = css.global`
  .navbar-menu {
    display: flex;
    align-self: center;
  }

  .top-header-link-wrapper,
  .top-header-login-link-wrapper {
    margin-left: 24px;

    .outline-button.textual {
      a.top-bar-link {
        div.content {
          font-weight: 400;
          font-size: 0.875rem;
        }
      }

      &:hover a.top-bar-link {
        text-decoration: none;
      }

      &:not(:hover) a.top-bar-link {
        transition: 0.2s ease all;
      }
    }
  }

  .top-header-login-link-wrapper {
    .outline-button.circled a.login-top-bar {
      font-size: 0.875rem;
    }
    .outline-button.circled:hover a.login-top-bar {
      border-color: ${colors["brand-purple"]};
      background-color: transparent;
      color: ${colors["brand-purple"]};
    }
  }

  .topbar-login-signup-wrapper {
    margin-left: 24px;
  }

  .topbar-language-picker-wrapper {
    width: 50px;
    margin-left: 16px;
  }
`;

export const menuStyles = css.global`
  .topbar-menu-wrapper {
    .menu-content-wrapper {
      padding-top: 8px;
    }
  }
  .use-cases {
    .menu-content-component {
      width: 420px;
      padding: 16px;
    }
  }
`;
