import css from "styled-jsx/css";
import colors from "/styles/colors";

export const componentStyles = css`
  .outline-button {
    &.default {
      &:hover a {
        background-color: ${colors["black"]};
        color: ${colors["white"]};
      }

      a {
        position: relative;
        display: block;
        border: 1px solid ${colors["black"]};
        color: ${colors["black"]};
        padding: 7px 18px;
        font-size: 0.875rem;
        vertical-align: middle;
        text-align: center;
        text-decoration: none;
        font-weight: 300;

        &.loading {
          cursor: default;
        }

        .content {
          &.loading {
            opacity: 0;
          }
        }
      }

      &.scroll-mode {
        a {
          padding: 8px 16px;
        }
      }

      &.light-theme:not(.scroll-mode) {
        a {
          color: ${colors["white"]};
          border-color: ${colors["white"]};
        }
        &:hover a {
          color: ${colors["light-blue"]};
          background-color: ${colors["white"]};
        }
      }
    }

    &.circled {
      &:hover a {
        background-color: ${colors["black"]};
        color: ${colors["white"]};
        border-width: 1px;
        border-style: solid;
        border-color: ${colors["black"]};
      }

      a {
        display: block;
        border: 1px solid ${colors["black"]};
        border-radius: 24px;
        color: ${colors["black"]};
        padding: 8px 24px;
        font-size: 1rem;
        vertical-align: middle;
        text-align: center;
        text-decoration: none;
        font-weight: 300;
      }

      &.scroll-mode {
        a {
          padding: 8px 16px;
        }
      }

      &.light-theme:not(.scroll-mode) {
        a {
          color: ${colors["white"]};
          border-color: ${colors["white"]};
        }
        &:hover a {
          color: ${colors["light-blue"]};
          background-color: ${colors["white"]};
        }
      }
    }

    &.textual {
      &:hover a {
        text-decoration: none;
      }

      a {
        display: block;
        color: ${colors["black"]};
        padding: 10px 0;
        font-size: 1rem;
        vertical-align: middle;
        text-align: center;
        text-decoration: none;
        font-weight: 300;
      }

      &.scroll-mode {
        a {
          padding: 10px 0;
        }
      }

      &.light-theme:not(.scroll-mode) {
        a {
          color: ${colors["white"]};
        }
      }
    }
  }
`;

export const loaderStyles = css.global`
  .outline-button.default {
    &:hover a svg {
      circle {
        stroke: white;
      }
    }

    a .spinner {
      position: absolute;
      left: calc(50% - 12px);
      top: calc(50% - 12px);
    }
  }
`;
