import css from "styled-jsx/css";
import colors from "styles/colors";

export const componentStyles = css`
  .menu-component {
    position: relative;

    .button-wrapper {
      display: inline-flex;
      align-items: center;
    }

    .menu-content-wrapper {
      position: absolute;
    }
  }
`;
