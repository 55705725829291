import { PureComponent } from "react";
import classnames from "classnames";
import throttle from "lodash.throttle";
import { TOP_BANNER_COOKIE } from "/constants/cookies";
import SocialShareButtonsComponent from "/components/core/social-share-buttons/social-share-buttons-component";
import { getCookie } from "/services/cookies-service";
import { MAX_DESKTOP_CONTENT_WIDTH } from "/constants/vp-sizes";
import TopBarBannerComponent from "./top-bar-banner/top-bar-banner-component";
import PictureComponent from "components/core/picture/picture-component";
import { componentStyles, homepageLogoStyles } from "./top-bar-base-component.scss";
import { MONDAY_LOGO_SRC } from "constants/links";

const THROTTLE_TIME = 10; //Execute a method only once per THROTTLE_TIME
const SCROLL_MODE_THRESHOLD = 2; //The amount of pixels from the top that will trigger the scrolled mode of the navbar

export default class TopBarBaseComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isInScrollMode: false,
      shouldShowTopBanner: false
    };

    this.toggleClassAccrodingToScroll = this.toggleClassAccrodingToScroll.bind(this);
    this.throttledToggleClassAccrodingToScroll = throttle(this.toggleClassAccrodingToScroll, THROTTLE_TIME, {
      trailing: true
    });
    this.closeTopBanner = this.closeTopBanner.bind(this);
  }

  componentDidMount() {
    this.toggleClassAccrodingToScroll();
    const { topBanner, showMenu } = this.props;
    const topBannerCookie = getCookie(TOP_BANNER_COOKIE);
    // const shouldShowTopBanner = topBanner && topBanner.show && !topBannerCookie;
    // this.setState({ shouldShowTopBanner });

    window.addEventListener("scroll", this.throttledToggleClassAccrodingToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledToggleClassAccrodingToScroll);
  }

  toggleClassAccrodingToScroll() {
    let isInScrollMode = (window.pageYOffset || document.documentElement.scrollTop) >= SCROLL_MODE_THRESHOLD;
    this.setState({ isInScrollMode });
    this.props.onScrollModeChange && this.props.onScrollModeChange(isInScrollMode);
  }

  closeTopBanner() {
    this.setState({ shouldShowTopBanner: false });
  }

  shouldRenderSocialShareButtons() {
    const { allowSocialButtons, socialButtonBlogStyle } = this.props;
    return allowSocialButtons && socialButtonBlogStyle;
  }

  render() {
    const {
      mobileTheme,
      theme,
      showMenu,
      headerBackgroundColor,
      stickyMode,
      allowSocialButtons,
      socialButtonsColor,
      socialButtonBlogStyle,
      className,
      topBanner,
      children,
      isSideNavOpen,
      maxWidth,
      sectionRenderer
    } = this.props;

    const { isInScrollMode } = this.state;
    const { shouldShowTopBanner } = this.state;

    return (
      <header className={className}>
        {shouldShowTopBanner && <TopBarBannerComponent />}
        <nav
          className={classnames(
            "navbar",
            { "scroll-mode": isInScrollMode },
            { "slidenav-open": isSideNavOpen },
            { "sticky-mode": stickyMode },
            { "with-top-bar-banner": shouldShowTopBanner }
          )}
          style={{ backgroundColor: headerBackgroundColor }}
        >
          <div className="top-bar-content" style={{ maxWidth }}>
            <div className="logo-wrapper">
              <a href="/" className="logo-link">
                <div
                  className={classnames("homepage-logo", theme, className, {
                    [`mobile-${theme}`]: theme
                  })}
                >
                  <PictureComponent src={MONDAY_LOGO_SRC} mobile={MONDAY_LOGO_SRC} className="monday-logo-image" />
                </div>
              </a>
              {sectionRenderer && sectionRenderer()}
            </div>

            {this.shouldRenderSocialShareButtons() && (
              <div className="social-share-buttons-wrapper">
                <SocialShareButtonsComponent
                  scrollMode={isInScrollMode}
                  direction="horizontal"
                  color={socialButtonsColor}
                  blogStyle={socialButtonBlogStyle}
                />
              </div>
            )}

            {showMenu && children}
          </div>
        </nav>

        <div
          className={classnames("navbar-padding", {
            "sticky-mode": stickyMode,
            "top-banner": shouldShowTopBanner
          })}
        />

        <style jsx>{componentStyles}</style>
        <style jsx>{homepageLogoStyles}</style>
      </header>
    );
  }
}

TopBarBaseComponent.defaultProps = {
  url: "//auth.monday.com/login",
  headerBackgroundColor: "white",
  stickyMode: false,
  allowSocialButtons: false,
  socialButtonsColor: "black",
  socialButtonBlogStyle: false,
  maxWidth: `${MAX_DESKTOP_CONTENT_WIDTH}px`
};
