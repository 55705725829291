import css from "styled-jsx/css";
import colors from "styles/colors";

export const menuStyles = css`
  .menu-content-component {
    box-shadow: 0px 2px 16px 0 rgba(0, 0, 0, 0.2);
    border: ${`1px solid ${colors["light-grey"]}`};
    background-color: ${colors["white"]};
    padding: 16px;
  }
`;
