import { PureComponent } from "react";
import Link from "next/link";
import classnames from "classnames";
import HamburgerMenuComponent from "../hamburger-menu/hamburger-menu-component";
import SideMenuComponent from "./side-menu/side-menu-component";
import TopBarBaseComponent from "../top-bar-base/top-bar-base-component";

import { mobileComponentStyles } from "./top-bar-mobile-component.scss";
import { withGenericConfig } from "/contexts/generic-config-context";

class TopbarMobileComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.toggleSlideNavState = this.toggleSlideNavState.bind(this);
    this.onScrollModeChange = this.onScrollModeChange.bind(this);

    this.state = {
      isSideNavOpen: false,
      isInScrollMode: false
    };
  }

  toggleSlideNavState(isSideNavOpen) {
    this.setState({ isSideNavOpen });
  }

  onScrollModeChange(isInScrollMode) {
    this.setState({ isInScrollMode });
  }

  renderHeaderLinks() {
    const { theme, url, mobileHeaderLinks } = this.props;
    const { isSideNavOpen, isInScrollMode } = this.state;
    return (
      <div className="navbar-menu">
        <HamburgerMenuComponent
          theme={theme}
          isInScrollMode={isInScrollMode}
          changeStateCallback={this.toggleSlideNavState}
          isOpen={isSideNavOpen}
        />
        <SideMenuComponent isOpen={isSideNavOpen} scrollMode={isInScrollMode} mobileHeaderLinks={mobileHeaderLinks} />
      </div>
    );
  }

  render() {
    return (
      <div>
        <TopBarBaseComponent {...this.props} onScrollModeChange={this.onScrollModeChange}>
          {" "}
          {this.renderHeaderLinks()}
        </TopBarBaseComponent>
        <style jsx>{mobileComponentStyles}</style>
      </div>
    );
  }
}

TopbarMobileComponent.defaultProps = {
  url: "//auth.monday.com/login",
  headerBackgroundColor: "white",
  stickyMode: false,
  allowSocialButtons: false,
  socialButtonsColor: "black",
  socialButtonBlogStyle: false
};

export default withGenericConfig(TopbarMobileComponent);
