import css from "styled-jsx/css";
import colors from "styles/colors";

import { MD } from "constants/vp-sizes";

export const componentStyles = css.global`
  .topbar-login-signup-component {
    display: flex;

    .topbar-login-signup-seperator {
      width: 1px;
      margin-right: 24px;

      background-color: #e3e3e3;
    }

    .topbar-login-signup-login-button-wrapper {
      margin-right: 16px;

      .outline-button {
        a.login-top-bar {
          div.content {
            font-weight: 400;
            font-size: 0.875rem;
          }
          &:hover {
            color: ${colors["brand-purple"]};
          }
        }
      }
    }

    .topbar-login-signup-base-signup-wrapper {
      display: flex;
      align-items: center;

      .signup-form-component {
        .signup-input {
          padding: 10px 19px;
          min-width: auto;
          max-width: 160px;
          border-radius: 4px;
          margin: 0 8px;
        }

        .signup-button {
          padding: 10px 19px;
        }
      }
    }
  }

  @media (max-width: 1124px) {
    .topbar-login-signup-component {
      .topbar-login-signup-base-signup-wrapper {
        display: none;
      }
    }
  }
`;
