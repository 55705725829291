import { PureComponent } from "react";
import { topBarBannerComponentStyles } from "./top-bar-banner-component.scss";
import Button from "/components/core/button/button-component";
import PricingModalSignupComponent from "/components/pages/pricing-page/pricing-modal-signup-component/pricing-modal-signup-component";

export default class TopBarBannerComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.openSignupModal = this.openSignupModal.bind(this);
    this.closeModalSignupCallback = this.closeModalSignupCallback.bind(this);

    this.state = {
      signupModalOpen: false
    };
  }

  openSignupModal() {
    this.setState({ signupModalOpen: true });
  }

  closeModalSignupCallback() {
    this.setState({ signupModalOpen: false });
  }

  render() {
    const { signupModalOpen } = this.state;
    const { bannerText } = this.props;

    return (
      <div className="top-bar-banner-component">
        <div className="banner-text">{bannerText}</div>
        <Button className="banner-signup-button" size="sm" onClickCallback={this.openSignupModal}>
          Sign up
        </Button>
        <style jsx>{topBarBannerComponentStyles}</style>

        {signupModalOpen && <PricingModalSignupComponent closeModalCallback={this.closeModalSignupCallback} />}
      </div>
    );
  }
}

TopBarBannerComponent.defaultProps = {
  bannerText: "Black Friday deals waiting inside"
};
