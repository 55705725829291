import { PureComponent } from "react";
import SpinnerComponent from "/components/enhancements/spinner/spinner-component";
import classnames from "classnames";
import { componentStyles, loaderStyles } from "./outline-button-component.scss";

export default class OutlineButtonComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }
  onClick(e) {
    const { onClickCallback } = this.props;
    if (onClickCallback) {
      onClickCallback(e);
    }
    return true; // continue the location change
  }

  render() {
    const { text, url, theme, buttonStyle, isInScrollMode, linkClassName, loading, onClickCallback } = this.props;
    const scrollModeClass = isInScrollMode ? "scroll-mode" : "";
    return (
      <div className={classnames("outline-button", theme, buttonStyle, scrollModeClass)}>
        <a href={url} className={classnames(linkClassName, { loading: loading })} onClick={this.onClick}>
          <div className={classnames("content", { loading })}>{text}</div>
          {loading && <SpinnerComponent className="spinner" color="black" size="24px" speed="slow" />}
        </a>
        <style jsx>{componentStyles}</style>
        <style jsx>{loaderStyles}</style>
      </div>
    );
  }
}

OutlineButtonComponent.defaultProps = {
  isInScrollMode: false,
  text: "",
  url: undefined, // when undefined - the button doesn't have a href (only using callback)
  theme: "",
  buttonStyle: "default", // Possible values: default, circled, textual.
  linkClassName: "",
  loading: false
};
