import { PureComponent } from "react";
import { modalSignup } from "./pricing-modal-signup-component.scss";
import SignupFormComponent from "components/core/signups/base-signup-form/signup-form-component";
import CloseCircular from "/static/svg/close-circular.svg";
import { withGenericConfig } from "/contexts/generic-config-context";

class PricingModalSignupComponent extends PureComponent {
  render() {
    const { closeModalCallback, translate } = this.props;

    return (
      <div className="modal-signup-container">
        <div className="close-button" onClick={closeModalCallback}>
          <CloseCircular />
        </div>
        <div className="main-title"> {translate("pricingPage.signupModalTitle")} </div>
        <div className="secondary-title"> {translate("pricingPage.signupModalSecondaryTitle")} </div>

        <div className="signup-wrapper">
          <SignupFormComponent />
        </div>

        <div className="bottom-part">
          <div className="line" />
          <div className="bottom-text"> {translate("pricingPage.signupModalStartFreeTrial")} </div>
          <div className="line" />
        </div>

        <style jsx>{modalSignup}</style>
      </div>
    );
  }
}

export default withGenericConfig(PricingModalSignupComponent);
