import css from "styled-jsx/css";

import { TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";

export default css`
  .top-bar-component {
    .top-bar-mobile-wrapper {
      display: none;
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .top-bar-component {
      .top-bar-desktop-wrapper {
        display: none;
      }

      .top-bar-mobile-wrapper {
        display: block;
      }
    }
  }
`;
