import PropTypes from "prop-types";
import { PureComponent } from "react";
import componentStyles from "./spinner-component.scss";

export default class SpinnerComponent extends PureComponent {
  speedSwitch(speed) {
    if (speed === "fast") return 600;
    if (speed === "slow") return 900;
    return 750;
  }

  getSpinner() {
    const { color, speed, gap, thickness, size, className } = this.props;
    return (
      <svg
        height={size}
        width={size}
        style={{ animationDuration: `${this.speedSwitch(speed)}ms` }}
        className={`__svg-spinner_circle ${className}`}
        role="img"
        viewBox="0 0 32 32"
      >
        <circle
          role="presentation"
          cx={16}
          cy={16}
          r={14 - thickness / 2}
          stroke={color}
          fill="none"
          strokeWidth={thickness}
          strokeDasharray={Math.PI * 2 * (11 - gap)}
          strokeLinecap="round"
        />
        <style jsx>{componentStyles}</style>
      </svg>
    );
  }

  render() {
    return this.getSpinner();
  }
}

SpinnerComponent.propTypes = {
  color: PropTypes.string,
  thickness: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8]).isRequired,
  gap: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
  speed: PropTypes.oneOf(["fast", "slow"]),
  size: PropTypes.string.isRequired
};

SpinnerComponent.defaultProps = {
  color: "rgba(0,0,0,0.4)",
  gap: 4,
  thickness: 4,
  size: "1em"
};
